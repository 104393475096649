import React, { Fragment } from "react";
// import { genTypeSchema } from "fast-typescript-to-jsonschema";
// import file from "../../../src/components/index.ts";

// const TemplatesPage = () => {
//   // generate data
//   genTypeSchema.genJsonDataFormFile(file);

//   // get all jsonschema data of current file
//   const json = genTypeSchema.genJsonData();

//   // get jsonschema of specific type
//   const jsonSchema = genTypeSchema.getJsonSchema(file, "ImageGallery");

//   // result
//   console.log(jsonSchema);
//   return (
//     <Fragment>
//       <h1>Template Portal</h1>
//       <code>{json}</code>
//     </Fragment>
//   );
// };

// export default TemplatesPage;

const TemplatesPage = () => {
  return (
    <Fragment>
      <h1>Template Portal</h1>
    </Fragment>
  );
};

export default TemplatesPage;
